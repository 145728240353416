export default {
  sidebar: [
    {
      name: 'Basic',
      path: 'basic'
    },
    {
      name: 'Social',
      path: 'social'
    },
    {
      name: 'Options',
      path: 'options'
    },
    {
      name: 'Addons',
      path: 'addons'
    },
    {
      name: 'Projects',
      path: 'projects'
    }
  ]
}
