<template>
  <div class="sidebar">
    <div class="sidebar__nav">
      <router-link
        :key="'Basic'"
        :to="'basic'"
        tag="div"
        class="sidebar__nav-item"
      >
        <usersvg class="svg-style" /><br>{{ 'Basic' }}
      </router-link>
    </div>
    <div class="sidebar__nav">
      <router-link
        :key="'Social'"
        :to="'social'"
        tag="div"
        class="sidebar__nav-item"
      >
        <social class="svg-style" /><br>{{ 'Social' }}
      </router-link>
    </div>
    <div class="sidebar__nav">
      <router-link
        :key="'Options'"
        :to="'options'"
        tag="div"
        class="sidebar__nav-item"
      >
        <options class="svg-style" /><br>{{ 'Options' }}
      </router-link>
    </div>
    <div class="sidebar__nav">
      <router-link
        :key="'Addons'"
        :to="'addons'"
        tag="div"
        class="sidebar__nav-item"
      >
        <addon class="svg-style" /><br>{{ 'Addons' }}
      </router-link>
    </div>
    <div class="sidebar__nav">
      <router-link
        :key="'Project'"
        :to="'projects'"
        tag="div"
        class="sidebar__nav-item"
      >
        <project class="svg-style" /><br>{{ 'Project' }}
      </router-link>
    </div>
  </div>
</template>

<script>
import nav from '../db/navigation'
import usersvg from '../assets/image/user-regular.svg'
import social from '../assets/image/share-alt-solid.svg'
import options from '../assets/image/edit-solid.svg'
import addon from '../assets/image/th-solid.svg'
import project from '../assets/image/clipboard-solid.svg'
export default {
  name: '',
  components: {
    usersvg,
    social,
    options,
    addon,
    project
  },

  data () {
    return {
      nav: nav.sidebar,
      usersvg: usersvg,
      social: social,
      options: options,
      addon: addon,
      project: project
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/variables.scss';

.svg-style{
  height: 20px;
}

.sidebar {
  border-right: 1px solid #ddd;
  background-color: #002D45;
  color: #fff;
  &__nav {
    &-item {
      padding: 25px 10px;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      &.active {
        background-color: $color-primary;
        color: #fff;
        &:hover {
          background-color: $color-primary;
          color: #fff;
        }
      }
      &:hover {
        background-color: #eee;
        color: #000;
      }
    }
  }
}
</style>
